/*
Canvas with React.js. Learn how to use canvas in React.js by… | by Lucas Miranda | Medium
https://medium.com/@pdx.lucasm/canvas-with-react-js-32e133c05258

fetch
https://www.javascripttutorial.net/javascript-fetch-api/


*/

import { ClassNameConfigurator } from '@mui/base';
import React, { useRef, useEffect } from 'react'
import { useStore } from "../store/useStore";

//let images = new Array(9).fill(new Image())
let images = []
for (let i = 0; i < 9; i++) {
    images[i] = new Image()
}


let svg
let context


const queryString = window.location.search;
// console.log(queryString);
const urlParams = new URLSearchParams(queryString);





const Canvas = ({ canvas, loader }) => {

    const config        = useStore(state => state.config)
    const top           = useStore(state => state.top)
    const bottom        = useStore(state => state.bottom)
    const styleTop      = useStore(state => state.top.style)
    const styleBottom   = useStore(state => state.bottom.style)
    const [trigger]     = useStore((state) => [state.trigger])


    // const canvasRef = useRef(null)
    const imgW = 1920
    const imgH = 1080
    let fakeRandArr, fakeRandArrShuffle
    let altSrc = ''
    let useFakeRandom = true   
    let styleVertical = true
    

    // console.log('urlParams.get(fakeRandom)', urlParams.get('fakeRandom'))
    // geht nicht, Server leitet um
    if (urlParams.get('random')     != null)    useFakeRandom   = false
    if (urlParams.get('npm start') != null)    altSrc          = '_translated'
    if (urlParams.get('horizontal') != null)    styleVertical   = false

    // alternativ mit #
    if(window.location.hash == '#random')       useFakeRandom   = false
    if(window.location.hash == '#translated')   altSrc          = '_translated'
    if(window.location.hash == '#horizontal')   styleVertical   = false

    //console.log('useFakeRandom', useFakeRandom)
    // console.log('FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF useFakeRandom', useFakeRandom)

 
    /*
    // Zum Erzeugen des der fakeRandomarrays

    fakeRandArr = [...Array(101).keys()].slice(1)
    console.log('fakeRandArr', fakeRandArr)
    shuffle(fakeRandArr)
    //shuffle(fakeRandArr)
    //shuffle(fakeRandArr)
    console.log('fakeRandArr shuffled', fakeRandArr)
    console.log(JSON.stringify(fakeRandArr))

    function shuffle(array) {
        array.sort(() => Math.random() - 0.5)
    }

    */
    // fixen array verwebden
    fakeRandArr        = [2,51,74,65,49,67,66,17,18,68,6,81,29,82,8,50,83,87,32,86,52,19,70,12,28,   60,11,88,33,1,37,20,39,76,34,13,23,22,69,21,78,11,72,71,16,3,79,95,55,24,    4,36,91,90,15,40,73,7,54,27,80,92,56,26,38,96,25,14,93,58,5,97,98,99,9,  30,100,64,89,35,61,10,57,77,41,62,43,59,63,44,94,42,48,75,45,46,53,85,47,84,31]
    fakeRandArrShuffle = [14,30,83,25,87,51,32,47,98,38,31,86,99,52,2,96,9,65,19,70,64,12,100,28,74,85,53,88,58,84,93,89,66,60,35,11,67,5,1,49,97,20,57,17,77,10,33,41,6,62,43,37,59,29,61,63,44,48,45,46,18,75,94,42,82,68,50,8,81,76,11,78,71,39,72,95,3,13,16,34,79,22,23,69,21,4,55,24,36,90,91,40,15,80,56,92,73,26,27,7,54]


    const getRandom = (seed) => {
        let r       
        if (useFakeRandom) {            
            let val = (parseInt(seed* 0.37))       
            //console.log('getRandom seed, val, val % 100', seed, val,  val % 100)  
            if (seed % 2)   r = fakeRandArr[        val % 100]             // ungerade: normalen array verwednen
            else            r = fakeRandArrShuffle[ val % 100]             // gerade: Invertierten Array fakeRandArrInv
        }                                                                       // console.log('fake r seed, r', seed, r)
        else {r = Math.random() * 100; }                                        // console.log('random seed, r', seed, r)       
        return r
    }



    // Zwischenspeichern der Randomconfiguration, Vermeidung von Artefakten
    const renderFromTmpObj = (context, useFacade, useColors, images, imgW, imgH) => {
        // console.log('window.configurationTmpObj useFacade', window.configurationTmpObj, useFacade)
        let len = window.configurationTmpObj[useFacade].length
        if (!config.conf2 && useFacade == 'bottom') return
        if (!len) return

        for (let j = 0; j < len; j++) {
            let imageOrColor = window.configurationTmpObj[useFacade][j][0]
            let path = window.configurationTmpObj[useFacade][j][1]
            // console.log('imageOrColor', imageOrColor)

            context.save()
            context.clip(path)

            if (useColors) {
                context.fillStyle = imageOrColor
                context.fillRect(0, 0, imgW, imgH)
            } else {
                context.drawImage(images[imageOrColor], 0, 0, imgW, imgH)
            }
            context.restore()
        }
    }


    if (window.sq) console.log("render Canvas")
    //console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC render Canvas")
    //console.log('configurationTmpObj', window.configurationTmpObj)

    const updateImages = (facade, noRandom) => {
        //console.log('updateImages', facade, noRandom)

        images[0] = new Image(); images[0].src = 'assets/objects/' + config.object + '/Base-' + config.pattern + '.webp'
        images[1] = new Image(); images[1].src = 'assets/objects/' + config.object + '/Overlay-' + config.pattern + '.webp' // top + bottom, 1 img
        images[2] = new Image(); images[2].src = 'assets/dummy.jpg' // Dummy
        //console.log('use', 'Overlay-' + config.pattern + '.webp')

        // top: 3,4,5
        images[3] = new Image();
        images[4] = new Image();
        images[5] = new Image();
        images[6] = new Image();
        images[7] = new Image();
        images[8] = new Image();


        const fileType = '.webp' // .jpg | .webp                 Einfamilienhaus jpg: 4,3 MB, webp: 2.08
        if (top.decors[0] !== undefined) { images[3].src = 'assets/objects/' + config.object + altSrc + '/' + top.decors[0][0] + (top.decors[0][0].indexOf('u') == -1 ? (top.decors[0][2] ? '_w' : '_s') : '') + fileType } else { images[3].removeAttribute('src') }
        if (top.decors[1] !== undefined) { images[4].src = 'assets/objects/' + config.object + altSrc + '/' + top.decors[1][0] + (top.decors[1][0].indexOf('u') == -1 ? (top.decors[1][2] ? '_w' : '_s') : '') + fileType } else { images[4].removeAttribute('src') }
        if (top.decors[2] !== undefined) { images[5].src = 'assets/objects/' + config.object + altSrc + '/' + top.decors[2][0] + (top.decors[2][0].indexOf('u') == -1 ? (top.decors[2][2] ? '_w' : '_s') : '') + fileType } else { images[5].removeAttribute('src') }
        if (bottom) { // bottom: 6,7,8}
            if (bottom.decors[0] !== undefined) { images[6].src = 'assets/objects/' + config.object + altSrc + '/' + bottom.decors[0][0] + (bottom.decors[0][0].indexOf('u') == -1 ? (bottom.decors[0][2] ? '_w' : '_s') : '') + fileType } else { images[6].removeAttribute('src') }
            if (bottom.decors[1] !== undefined) { images[7].src = 'assets/objects/' + config.object + altSrc + '/' + bottom.decors[1][0] + (bottom.decors[1][0].indexOf('u') == -1 ? (bottom.decors[1][2] ? '_w' : '_s') : '') + fileType } else { images[7].removeAttribute('src') }
            if (bottom.decors[2] !== undefined) { images[8].src = 'assets/objects/' + config.object + altSrc + '/' + bottom.decors[2][0] + (bottom.decors[2][0].indexOf('u') == -1 ? (bottom.decors[2][2] ? '_w' : '_s') : '') + fileType } else { images[8].removeAttribute('src') }
        }


        let len = images.length
        let imagesLoaded = 0;
        let imageCount = 0

        /*
        // fetchbasierend
        for (var i = 0; i < len; i++) {
            imageCount++
            fetch(images[i].src).then().then(               
                function () {
                    imagesLoaded++;
                    if (imagesLoaded == imageCount) {
                        console.log('################################################################', imagesLoaded, imageCount)
                        if (window.masks) {
                            svg = window.masks
                            editCanvas([facade, noRandom])
                        } else {
                            fetch('assets/objects/' + config.object + '/masks.svg')
                                .then(response => response.text()).then(response => {
                                    svg = response;
                                    window.masks = svg
                                    editCanvas([facade, noRandom])
                                });
                        }
                    }
                } // end function
            )
        }
        */


        //console.log('images', images)

        
        // onload basierend
        //console.log('images len', images, len)                    
        for(var i=0; i<len; i++) {
            //console.log(i, images[i].src)
            if (images[i].src) {
                imageCount++
                //console.log(images[i].src, imageCount)
                images[i].onload = function() {
                    imagesLoaded++;

                    // console.log('onload', this.src, imagesLoaded)


                    if (imagesLoaded == imageCount) {               // console.log('Canvas useEffect imagesLoaded', imagesLoaded)
                        //console.log('####################', imagesLoaded, imageCount)
                        if (window.masks) {
                            svg = window.masks                     // masks sind da!
                            editCanvas([facade, noRandom])
                        } else { 
                            // masks neu laden 
                            fetch('assets/objects/' + config.object + '/masks-' + config.pattern + '.svg')                           
                            .then(response => response.text())
                            .then(response => {
                                svg = response; 
                                //console.log('--> use Masks', config.pattern)
                                window.masks = svg
                                editCanvas([facade, noRandom])
                            });
                        }
                    }
                }
            }
            
        }

    }

    





    useEffect(() => {
        //console.log('+++ Canvas useEffect trigger ' + trigger)
        if (trigger[0] == 'both') {
            updateImages('top', false)
            updateImages('bottom', false)
        } else {
            updateImages(trigger[0], trigger[1])
        }

    }, [trigger])








    const editCanvas = (arr) => {
        //console.log('--------------> editCanvas arr', arr)
        if (!arr[0]) return

        let facade   = arr[0]
        let noRandom = arr[1]

        if (!config.conf2 && facade == 'bottom') return
        //console.log('-----------------------------> editCanvas facade noRandom', facade, noRandom)

        const useColors = false
        let tbMode
        if (facade == 'top') tbMode = top
        else tbMode = bottom
        let sliderNum = tbMode.decors.length
        let decorsArr = tbMode.decors

        let decorCol1, decorCol2, decorCol3, useImage
        if (facade == 'top') {
            decorCol1 = 3
            decorCol2 = 4
            decorCol3 = 5
        } else {
            decorCol1 = 6
            decorCol2 = 7
            decorCol3 = 8
        }
        if (useColors) {
            decorCol1 = '#FFFF00'
            decorCol2 = '#11e2e3'
            decorCol3 = '#ff00ff'
        }
        let fillCol1 = decorCol1
        let fillCol2 = decorCol2
        let fillCol3 = decorCol3

        let modulo2  = 2
        let modulo3a = 3
        let modulo3b = 3

        // console.log('------------> canvas', canvas)
        const canvasElement = canvas.current
        context = canvasElement.getContext('2d')

        


        // Der modulo Wert wird für den kleinerern Anteil bestimmt     
        if (sliderNum == 2) {
            if (decorsArr[0][1] < 50) {
                modulo2 = Math.round(100 / decorsArr[0][1])
            } else {
                fillCol1 = decorCol2
                fillCol2 = decorCol1
                modulo2 = Math.round(100 / decorsArr[1][1])
            }
            //console.log('decorsArr[0][1] decorsArr[1][1]', decorsArr[0][1], decorsArr[1][1])
            //console.log('modulo2', modulo2)
        }

       
        // Der modulo Wert wird für den kleinerern Anteil bestimmt  
        let collSet         = new Set()
        let posArr          = [0,1,2,3,4,5,6,7,8,9] // daraus werden die Positionen gegzogen
        let posArrLenght    = posArr.length
       
        if (sliderNum == 3) {      
             /*     
            if (decorsArr[0][1] < 50) {
                modulo3a = Math.min(100, Math.round(100 / decorsArr[1][1]))
                modulo3b = Math.min(100, Math.round(100 / decorsArr[2][1]))
                fillCol1 = decorCol2
                fillCol2 = decorCol3
                fillCol3 = decorCol1
            } else if (decorsArr[1][1] < 50) {
                modulo3a = Math.min(100, Math.round(100 / decorsArr[2][1]))
                modulo3b = Math.min(100, Math.round(100 / decorsArr[0][1]))                
                fillCol1 = decorCol3
                fillCol2 = decorCol1
                fillCol3 = decorCol2
            } else if (decorsArr[2][1] < 50) {
                modulo3a = Math.min(100, Math.round(100 / decorsArr[0][1]))
                modulo3b = Math.min(100, Math.round(100 / decorsArr[1][1]))               
                fillCol1 = decorCol1
                fillCol2 = decorCol2
                fillCol3 = decorCol3
            }
            */

            // Neue Methode, sukzessives herausnehmen und umgruppieren
            let sliderPosArr
            sliderPosArr    = JSON.parse(JSON.stringify(    [decorsArr[0], decorsArr[1], decorsArr[2]]    ))
            sliderPosArr[0][2] = 1 // da beim sortieren ddie Reihenfolge verändert wird, wird sie im Array zwischengespeichert.  sliderPosArrX[2][2] = verseit auf das 3. Item im Decorarray
            sliderPosArr[1][2] = 2
            sliderPosArr[2][2] = 3
            // Sortieren, die kleinsten Werte haben die größten Abstände, werden zuerst rausgenommen
            // Bsp: 0.1 = jedes 10te, 0.2 = jedes 5te
            sliderPosArr.sort(function(a, b) {
                return a[1] - b[1];
            })
            //console.log('sliderPosArr', sliderPosArr)          
           
            for(let i=0; i<sliderPosArr.length; i++) {
                let val = sliderPosArr[i][1] / posArrLenght // in diesem Fall 10
                let nth = parseInt(posArrLenght / val)                
                if (i==2) nth = 1                                // damit Modulo immer 0 ist, jedes Element genommen wird, beim 3. Durchlauf wird nur noch aufgefüllt, was übrrig ist
                // console.log('i, val, nth', i, val, nth)
                // console.log('posArr', posArr)
                let keyDelColl = []
                for(let j=0; j<posArr.length; j++) {                 
                    //console.log('posArr.length', i, '...', j)
                    //console.log('i ... j', i, '...', j)
                    
                    if (j % nth == 0) {                   
                        //console.log('i ... j', i, '...', j)
                        let key = posArr[j] // jedes nthe Element rauskopieren
                        keyDelColl.push(j)
                        // console.log(`i:${i} ... j:${j}, nth: ${nth},j % nth:${j % nth},  key:${key}`)
                        // console.log('--> key', key)
                        collSet['_' + key] = sliderPosArr[i][2] // Zuordnung wiederhertellen
                    }
                } 
                //console.log('keyDelColl', keyDelColl)
                keyDelColl.reverse() // die Positionen der zu löschenen Werte, reverse(): von hinten rauslöschen
                keyDelColl.forEach((ndx)=> {posArr.splice(ndx,1)})
                // console.log('posArr after delete', posArr)
            }
            //console.log('collSet', collSet)
        }


        const doc = new DOMParser().parseFromString(svg, "image/svg+xml") // console.log('doc', doc)
        context.globalAlpha = 1
        context.globalCompositeOperation = 'source-over'

        // Hintergundbild
        //if (drawBG) context.drawImage(images[0], 0, 0, imgW, imgH)
        context.drawImage(images[0], 0, 0, imgW, imgH) // Hintergundbild immer zeichnen

        let styleVal
        if (facade == 'top') styleVal = styleTop
        else styleVal = styleBottom

        let query = 'path'
        if (bottom && bottom.decors.length) {
            if (facade == 'top') query = "g#top path"
            else query = "g#bottom path"
        }


        let allPath = doc.querySelectorAll(query)
        // console.log('query', query)
        // console.log('allPath', allPath)

        // Schleife alle Paths
        let i = 0
        allPath.forEach((pathEl) => {
            let pathData = pathEl.getAttribute("d")
            const path      = new Path2D(pathData);
            let idArr       = pathEl.id.split('.')
            let styleItem
            if (styleVertical)  styleItem = parseInt(idArr.splice(1,1))          // vertikal anordnen
            else                styleItem = parseInt(idArr.pop())               // horizontal
            //console.log('pathEl.id, styleItem', pathEl.id, styleItem)

            context.save()
            if (sliderNum == 1) {
                context.fillStyle = useImage = decorCol1
            } else if (sliderNum == 2) {
                let r = getRandom(i * decorsArr[0][1])                                        // i*decorsArr[0][1]: zusätzlicher Faktor, für mehr Random
                //console.log('i * decorsArr[0][1], r', i * (decorsArr[0][1]), r)
                if (r < decorsArr[0][1])    context.fillStyle = useImage = decorCol1 
                else                        context.fillStyle = useImage = decorCol2
                //console.log('context.fillStyle', context.fillStyle)

            } else if (sliderNum == 3) {
                let pos         = styleItem % posArrLenght                                  //console.log('pos', pos)
                let dokorNum    = collSet['_'+ pos ]
           
                //console.log(    'dokorNum, eval(decorCol + dokorNum)',      dokorNum, eval('decorCol' + dokorNum)    )
                context.fillStyle = useImage = eval('decorCol' + dokorNum)              

                let r = getRandom(i*decorsArr[0][1] * decorsArr[1][1])                       // i*decorsArr[0][1]: zusätzlicher Faktor, für mehr Random
                if      (r < decorsArr[0][1]) context.fillStyle = useImage = decorCol1
                else if (r > decorsArr[0][1] && (r < decorsArr[0][1] + decorsArr[1][1])) context.fillStyle = useImage = decorCol2
                else context.fillStyle = useImage = decorCol3
            }





            // Slyle Slider Einfluss berechnen
            if (sliderNum > 1) {
                let r = getRandom(i) 

                if (r > (100 - styleVal)) {  // styleVal : 0 - 100, geordnet = 100                   
                    let lastItem = parseInt(idArr.pop())
    
                    // _________________________________________
                    // 2 Decore abwechseln
                    if (sliderNum == 2) {
                        if (lastItem % modulo2 == 0) {
                            context.fillStyle = useImage = fillCol1
                        } else {
                            context.fillStyle = useImage = fillCol2
                        }
                    }

                    // _________________________________________
                    // 3 Decore abwechseln
                    else if (sliderNum == 3) {
                        let pos         = lastItem % posArrLenght               //console.log('pos', pos)
                        let dokorNum    = collSet['_'+ pos ]
               
                        //console.log(    'dokorNum, eval(decorCol + dokorNum)',      dokorNum, eval('decorCol' + dokorNum)    )
                        context.fillStyle = useImage = eval('decorCol' + dokorNum)
                    }
                }
            }


            context.clip(path)

            //console.log('useColors, imgW, imgH, useImage', useColors, imgW, imgH, useImage)
            if (useColors) context.fillRect(0, 0, imgW, imgH)                  // Farben verwenden
            else context.drawImage(images[useImage], 0, 0, imgW, imgH)       // Decore verwenden


            // /wischenspeichern
            // console.log('configurationTmpObj, facade', configurationTmpObj, facade)           
            if (!noRandom) {
                // console.log("recording noRandom", noRandom)
                if (useColors) window.configurationTmpObj[facade][i] = [context.fillStyle, path]
                else window.configurationTmpObj[facade][i] = [useImage, path]
            }

            context.restore()
            i++
        })



        // console.log('images', images)        
        // console.log('render facade, noRandom', facade, noRandom)

        // andere Fassade zeichnen aus configurationTmpObj 
        if (true) {
            let useFacade
            facade == 'top' ? useFacade = 'bottom' : useFacade = 'top'

            renderFromTmpObj(context, useFacade, useColors, images, imgW, imgH)
            if (noRandom) { renderFromTmpObj(context, facade, useColors, images, imgW, imgH) }

        }


        // Vordergrundelemente + Schattierungen
        context.globalAlpha = 1
        context.globalCompositeOperation = 'source-over'

        context.drawImage(images[1], 0, 0, imgW, imgH)

        //if (facade=="top")  context.drawImage(images[1], 0, 0, imgW, imgH)
        //else                context.drawImage(images[2], 0, 0, imgW, imgH)
        //console.log('end configurationTmpObj', window.configurationTmpObj)

        //console.log('----------------> loader', loader)
        //document.querySelector('.loader').classList.add('hidden')
        loader.current.classList.add('hidden')

    }





    return <canvas ref={canvas} width="1920" height="1080" />
}







export default Canvas
