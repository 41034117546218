import * as React from 'react';
import {useRef, Fragment} from 'react'
import t from './Translation.js'
import {useStore} from "./store/useStore";
import ChooseLanguage from './Components/ChooseLanguage.js'



function Header(props) {
	const [setImageSliderActive]  = useStore((state) => [state.setImageSliderActive])
	const [setSaveProjectActive]  = useStore((state) => [state.setSaveProjectActive])
	const [setProductListActive]  = useStore((state) => [state.setProductListActive])
    const [setHelpLayerActive]    = useStore((state) => [state.setHelpLayerActive])
    const [goShoppingCart]        = useStore((state) => [state.goShoppingCart])
    const [setSelectObjectActive] = useStore((state) => [state.setSelectObjectActive])

    

	if (window.sq) console.log("render Header")

	const lang 			= useStore(state => state.lang)
	const switchLang 	= useStore(state => state.switchLang)
	const openContact 	= useStore(state => state.openContact)
	const fullScreen 	= useStore(state => state.fullScreen)
    const langContainer = useRef()



    const toggleLangSelect = (mode) => {
        //console.log('langContainer', langContainer)
        if (mode)    langContainer.current.classList.add('visible')
        else         langContainer.current.classList.remove('visible')
    }

	const pages = [
		{key: 'project', 		txt: t[lang].selectobject, 			onClick: () => {setSelectObjectActive(true)}},		
		{key: 'myprojects', 	txt: t[lang].myprojects, 			onClick: () => {setSaveProjectActive(true)}},
		{key: 'list', 			txt: t[lang].productlist, 			onClick: () => {setProductListActive(true)}},
	]



	const buttons = [
        {key: 'shoppingcart',   txt: t[lang].shoppingcart, 	onClick: () => {goShoppingCart()}, xtrastuff: <div className="shoppingcart-counter hidden"></div>},
		{key: 'compare', 		txt: t[lang].compare, 		onClick: () => {setImageSliderActive(true)}},
		{key: 'save', 			txt: t[lang].save, 			onClick: () => {setSaveProjectActive('save')}},
		{key: 'print', 			txt: t[lang].print, 		onClick: () => {window.print()}},
		{key: 'fullscreen', 	txt: t[lang].fullscreen, 	onClick: fullScreen},
		{key: 'contact', 		txt: t[lang].contact, 		onClick: openContact},
		{key: 'help', 			txt: t[lang].help, 			onClick: () => {setHelpLayerActive(true)}},
		{key: 'lang', 			txt: t[lang][lang], 		onClick: switchLang},
	]


	return (
		<>
            <header>
                    <ul className="bar logobar">
                        <li><a key="lPfl" onClick={() => document.location.reload()} className="pfleiderer-logo"><img src="assets/pfleiderer-logo.svg" alt="Pfleiderer Logo"/></a></li>
                        <li><a key="lDu"  onClick={() => document.location.reload()} className="duropal-logo"><img src="assets/duropal-logo.svg" alt="Duropal Logo"/></a></li>
                        <li><a key="lTh"  onClick={() => document.location.reload()} className="thermopal-logo"><img src="assets/thermopal-logo.svg" alt="Thermopal Logo"/></a></li>
                        <li><a key="lwcs" onClick={() => document.location.reload()} className="wcs-logo"><img src="assets/wcs-logo.svg?1" alt="WCS Logo"/></a></li>
                    </ul>
                    <ul className="bar menubar">
                        {pages.map((page) => (
                            <li key={page.key}><a onClick={page.onClick}>{page.txt}</a></li>
                        ))}
                    </ul>	
                    <ChooseLanguage langContainer={langContainer} toggleLangSelect={toggleLangSelect}/>						
            </header>
            <ul className="bar buttonbar">
                {buttons.map((button, index) => (
                 <Fragment key={button.key}>
                 
                 {button.key != 'lang' ? <li className={button.key} onClick={button.onClick}><a>{button.xtrastuff}{button.txt}</a></li> :
                                         <li className={button.key} onMouseOut={(e)=>{if (e.relatedTarget && e.relatedTarget.classList.contains('help')) toggleLangSelect()}} onMouseOver={toggleLangSelect}><a>{button.txt}</a></li>}

                 </Fragment>                 
                ))}
            </ul>
	    </>
	)
}

export default Header;

