import './App.css';
import React from 'react';
import {useRef, useState, useEffect, Suspense} from 'react'
import Header from './Header';
import Canvas from './Components/Canvas';
import FacadeButtons from './Components/FacadeButtons';
import Sidebar from './Components/Sidebar';
import Compare from './Components/Compare';
import ChooseDecor from './Components/ChooseDecor';
import ChooseObject from './Components/ChooseObject';
import SaveProject from './Components/SaveProject';
import ProductList from './Components/ProductList';
import HelpLayer from "./Components/HelpLayer";
import {useStore} from "./store/useStore";
import Loader from "./Components/Loader";
import Spinner from "./Components/Spinner";




window.version  = 2.0
window.sq       = false

// console.log('document.location.href', document.location.href)
if (document.location.href.indexOf('localhost') != -1) {
    window.PFL_AUTHENTICATED = true // zum Testen
    window.isLocalhost = true
}






window.q = [0,0,0]
window.activeSlider 	= null // aktuell verwendeter Decor-Slider
window.resizeIsSet 		= false
window.configured 		= false // wurde etwas konfiguriert? dann bestehende Werte übernehmen

window.configurationTmpObj = {'top': [], 'bottom': []}
window.masks = null 
window.changes = 0
// console.log('window.changes', window.changes)

window.baseUrl = ''
if (document.location.href.indexOf('localhost') != -1) {
	//window.baseUrl 		= 'https://www.pfleiderer.com'
	window.baseUrl 		= 'https://pfleiderer3.schwarz.hosting'
}
// console.log('window.baseUrl', window.baseUrl)




//console.log('window.FACADE_CONFIGURATOR', window.FACADE_CONFIGURATOR)
//console.log('window.FACADE_CONFIGURATOR.decors', window.FACADE_CONFIGURATOR.decors)
// console.log('window.FACADE_CONFIGURATOR.decors.length', window.FACADE_CONFIGURATOR.decors.length)





function App() {
	// const [trigger, setTrigger] = useState([]); 									// https://timmousk.com/blog/react-call-function-in-child-component/	
	if(window.sq) console.log("### render App")
   
	//const lang 								= useStore(state => state.lang)
	const [config]        					= useStore((state) => [state.config])
	const [setWindowSize]  					= useStore((state) => [state.setWindowSize])
	const [sideBarActive, setSideBarActive] = useStore((state) => [state.sideBarActive, state.setSideBarActive])
	const canvas = useRef()
	const loader = useRef()
	
	


	// Versions check, clear local storage
	const [version, setVersion] = useStore((state) => [state.version, state.setVersion])
	const [setConfig, setTop, setBottom, setLang] = useStore((state) => [state.setConfig, state.setTop, state.setBottom, state.setLang])
	const [setTrigger] = useStore((state) => [state.setTrigger])
	if (version != window.version) {			
		//console.log('************************ localStorage', localStorage)
		//useStore.persist.clearStorage()
		//localStorage.clear();

		setVersion(window.version)
		setTop(window.baseConfig.top)
		setBottom(window.baseConfig.bottom)
		setConfig(window.baseConfig.config)
		window.configurationTmpObj = {'top': [], 'bottom': []}
        setTrigger(['both', false])
	}
	
	
	if (!window.resizeIsSet) {
		window.addEventListener('resize', function() {setWindowSize([window.innerWidth, window.innerHeight])})	
		window.resizeIsSet = true
	}


    const trackIt = (e) => {
        //e.preventDefault();
        //e.returnValue = '';
        if (window.gtag) { (typeof window.gtag === "function" && window.gtag("event", "facadeconfigurator_configure", { "facadeconfigurator_object": config.object, "facadeconfigurator_mounting": config.mounting }))}
        // window.confirm('Leave page?')
    } 

	useEffect(() => {
		document.title = 'Pfleiderer Fassadenkonfigurator';
		//console.log('/////////////////////////////////////////////////////////////// app.js canvas', canvas )
        // tracking
        // https://stackoverflow.com/questions/13443503/run-javascript-code-on-window-close-or-page-refresh
        window.addEventListener("beforeunload", trackIt);
        return () => document.removeEventListener('beforeunload', trackIt)

	}, [trackIt]);


    useEffect(() => {      
		setLang(window.language)  
	}, []);


    window.setSpinner = (mode)  => {
        let spinner = document.getElementById('spinner')
        if (mode) {
            let rect = document.body.getBoundingClientRect()
            spinner.style.left = rect.left + rect.width/2 + 'px'
            spinner.style.top  = rect.top + rect.height/2 + 'px'
            spinner.classList.add('visible')
        } else {
            spinner.classList.remove('visible')
        }       
    }
    

    window.chckClck = (e)  => {
        //console.log('e', e)
        if (e.target.classList.contains('active')) return true
    }


	window.toggleFullScreen = ()  => {
		if (!document.fullscreenElement &&    // alternative standard method
			!document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}


	return (
		<div className={sideBarActive ? 'App has-sidebar': 'App'}>
			<Loader loader={loader}/>
			<Header canvas={canvas}/>
            <Spinner/>

			<div className="content-container">
				<div className="canvas-container">

					<Canvas canvas={canvas} loader={loader} width="1920" height="1080"></Canvas>							
					<FacadeButtons/>
					<Compare canvas={canvas} />

				</div>
				<Sidebar />
			</div>

			<ChooseObject canvas={canvas} />
			<ChooseDecor/>
            <HelpLayer/>
			<SaveProject canvas={canvas}/>
			<ProductList/>			
		</div>
	)
}

export default App;


